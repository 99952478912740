var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{staticClass:"py-2 lightyellow"},[_c('Container',{attrs:{"fluid":{ xs: true, md: false }}},[_c('Carousel',{attrs:{"items":_vm.items,"options":{
				panelsPerView: { xs: 2.5, sm: 4.5, md: 6 },
				itemsPadding: 3,
				arrows: { md: true },
				arrowsMargin: 15,
				arrowsClass: 'blue--text',
				itemWrapperClass: 'py-1',
			},"flicking-options":{
				align: 'prev',
				bound: true,
				circular: { xs: true, md: false },
				moveType: { xs: 'freeScroll', md: 'snap' },
			}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
			var item = ref.item;
return [_c('SafeLink',{attrs:{"to":item.link}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var hover = ref.hover;
return [_c('Media',{staticClass:"img-item",class:{ 'img-item--hover': hover },attrs:{"src":item.img,"aspect-ratio":1,"contain":"","eager":""}})]}}],null,true)})],1)]}}],null,false,1214691265)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }