<script>
export default {
	lang: 'home',
	data() {
		return {
			data: this.$srv('Home_F_MoreInfo'),
		}
	},
	props: {
		img: String,
	},
}
</script>

<template>
	<Container class="py-12" v-if="data">
		<v-row>
			<v-col cols="12" sm="6" class="pr-sm-0 py-0">
				<Media
					:src="img || data.img"
					:aspect-ratio="1.56"
					:class="{ 'rounded-l-xl': !$vuetify.breakpoint.xs, 'rounded-t-xl': $vuetify.breakpoint.xs }"
					cover
				/>
			</v-col>
			<v-col cols="12" sm="6" class="pl-sm-0 py-0">
				<div
					class="blue h100 d-flex flex-column justify-center align-center px-12 py-12 py-sm-0"
					:class="{ 'rounded-r-xl': !$vuetify.breakpoint.xs, 'rounded-b-xl': $vuetify.breakpoint.xs }"
				>
					<div class="font-4 font-sm-2 font-md-5 text-center white--text" style="line-height: 1.1">
						<div class="font-weight-bold preline">
							{{ $langText('¡No te quedes\ncon ninguna duda!') }}
						</div>
						<div class="pt-1 preline">
							{{ $langText('Aquí encontrarás todas\nlas respuestas') }}
						</div>
					</div>
					<SafeLink class="pt-10" :to="data.ctaLink" v-if="data.ctaText">
						<Button color="orange" rounded elevation="0" x-large class="px-16">
							{{ data.ctaText }}
						</Button>
					</SafeLink>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>
