<script>
import roosterImg from './rooster.jpg'

export default {
	lang: 'home',
	data() {
		return {
			items: [
				{
					icon: 'earth',
					name: this.$langText('Envíos\na todo el mundo'),
					mname: this.$langText('Envíos a todo\nel mundo'),
					// content: this.$lan('Llevamos tu libro hasta la puerta de tu casa ¡dondequiera que estés!'),
				},
				{
					icon: 'shield-check-outline',
					name: this.$langText('Envío\nseguro'),
					// content: this.$lan('Recibe tu libro en 20 días'),
					hide: this.$vuetify.breakpoint.xs,
				},
				{
					icon: 'star-outline',
					name: this.$langText('Excelente\ncalidad'),
					// content: this.$lan(
					// 	'Libros ilustrados a todo color, de excelente calidad, para disfrutar en familia'
					// ),
				},
				{
					icon: 'pencil-circle-outline',
					name: this.$lang('Personalizado'),
					// content: this.$lan('No hay un libro igual a otro porque tu peque es el protagonista'),
					hide: this.$vuetify.breakpoint.xs,
				},
				{
					icon: 'gift-outline',
					name: this.$langText('Regalo\nideal'),
					// content: this.$lan(
					// 	'Para cumpleaños, aniversarios, bautismos…o simplemente para demostrar cuánto lo quieres'
					// ),
				},
				{
					icon: 'heart-outline',
					name: this.$langText('Para\ntoda la vida'),
					// content: this.$lan('Sin dudas un regalo único y personal que atesorarán para siempre'),
				},
			],
			roosterImg,
		}
	},
}
</script>

<template>
	<Container class="py-10">
		<v-row>
			<v-col cols="12" class="d-flex align-center justify-center pb-12">
				<Media
					:src="roosterImg"
					:width="250"
					:max-width="250"
					:aspect-ratio="1.206"
					v-if="$vuetify.breakpoint.mdAndUp"
					eager
				/>
				<div
					class="font-3 font-md-5 font-weight-bold blue--text text-center text-md-left px-8 px-md-0"
					:style="{ 'line-height': $vuetify.breakpoint.smAndDown ? '1.2' : '' }"
				>
					{{ 'Creamos historias con el nombre de tu persona favorita.' | lang }}
				</div>
			</v-col>
			<template v-for="item in items">
				<v-col v-if="!item.hide" :key="item.name" cols="6" sm="4" md="2">
					<div class="text-center black--text d-flex flex-column justify-center">
						<v-icon x-large class="blue--text"> mdi-{{ item.icon }} </v-icon>
						<div
							class="green--text font-weight-bold py-1 py-sm-4 font-2"
							:style="{ height: `${$vuetify.breakpoint.xs ? '55px' : '70px'}` }"
							style="white-space: pre-line; line-height: 1"
						>
							{{ ($vuetify.breakpoint.xs && item.mname) || item.name }}
						</div>
						<!-- <div>
							{{ item.content }}
						</div> -->
					</div>
				</v-col>
			</template>
		</v-row>
	</Container>
</template>
