<script>
export default {
	data() {
		return {
			items: this.$srv('Home_A_Sliders', []),
		}
	},
	computed: {
		imagesRatio() {
			return this.$vuetify.breakpoint.xs ? 500 / 786 : 1920 / 740
		},
	},
}
</script>

<template>
	<div v-if="items.length">
		<Carousel
			class="main-carousel"
			:items="items"
			:options="{ autoplay: 4000, arrows: { md: true }, arrowsClass: 'darkblue--text' }"
			:flicking-options="{ circular: true, moveType: ['strict', { count: 1 }] }"
		>
			<template #panel="{ item }">
				<SafeLink :to="item.link">
					<Media
						:src="$vuetify.breakpoint.xs ? item.imgMobile : item.img"
						:aspect-ratio="imagesRatio"
						contain
						eager
					/>
				</SafeLink>
			</template>
			<template #free-slot="{ currentIndex, moveTo }">
				<div class="pag align-center justify-space-between d-none d-md-flex">
					<div
						class="pag-item"
						:class="{ active: currentIndex == i - 1 }"
						v-for="i of items.length"
						:key="i"
						@click="moveTo(i - 1)"
					></div>
				</div>
			</template>
		</Carousel>
	</div>
</template>
<style scoped>
.main-carousel >>> .arrow--left {
	top: 93% !important;
	left: 4% !important;
}
.main-carousel >>> .arrow--right {
	top: 93% !important;
	right: unset !important;
	left: 11% !important;
}
.pag {
	position: absolute;
	top: 92.1%;
	left: 7%;
	width: 5%;
	z-index: 4;
	padding: 4px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}
.pag-item {
	border-radius: 8px;
	height: 8px;
	background: rgba(0, 0, 0, 0.3);
	width: 100%;
	margin: 0 2px;
	transition: all 0.3s;
	cursor: pointer;
}
.pag-item.active {
	background: var(--v-darkblue-base);
	width: 175%;
}
</style>
