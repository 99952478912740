import Vue from 'vue'
import { sync as vuexRouterSync } from 'vuex-router-sync'
import App from './site/App.vue'
import store from './store'
import router from './router'
import PortalVue from 'portal-vue'
import { AssignPlugin } from '@/__shared/utils/lib_utils'
import { EventerPlugin } from '@/__shared/utils/lib_eventer'
import Srv from '@/__shared/utils/lib_srv'
import VueMeta from 'vue-meta'
import { isCrawlerAgent } from '@/site/site/prerender'

import './styles.scss'
import './autoloader'

if (!isCrawlerAgent) {
	Vue.config.productionTip = false
	vuexRouterSync(store, router)

	Vue.use(PortalVue)
	Vue.use(AssignPlugin)
	Vue.use(EventerPlugin)
	Vue.use(Srv)
	Vue.use(VueMeta, { refreshOnceOnNavigation: true })

	// Load Global Styles
	let requireStyle = require.context('@/site', true, /\/styles_.+\.(css|scss|sass)$/)
	for (let file of requireStyle.keys()) requireStyle(file)

	// Load Plugins
	let requirePlugin = require.context('@/site', true, /\/plugin_[\w-]+\.js$/)
	let plugins = {}
	for (let file of requirePlugin.keys()) {
		let plugin = requirePlugin(file).default
		Object.assign(plugins, plugin || {})
	}

	new Vue({
		...plugins,
		router,
		store,
		render: (h) => h(App),
	}).$mount('#app')
}
