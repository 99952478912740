<script>
export default {
	props: {
		image: String,
		ctaTxt: String,
		ctaLink: String,
		ratio: Number,
	},
}
</script>

<template>
	<SafeLink :to="ctaLink" class="full-height">
		<v-card height="100%" width="100%" elevation="3" class="d-flex align-content-end rounded-xl">
			<Media height="100%" :src="image" :aspect-ratio="ratio" eager>
				<div class="alignContentBottom">
					<v-card-text class="white--text pb-6">
						<div class="text-center">
							<Button v-if="ctaTxt" elevation="3" x-large class="secondary--text green px-12">
								{{ ctaTxt }}
							</Button>
						</div>
					</v-card-text>
				</div>
			</Media>
		</v-card>
	</SafeLink>
</template>

<style scoped>
.alignContentBottom {
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
</style>
