<script>
export default {
	name: 'PriceLayout',
	props: {
		prevPrice: Number,
		price: Number,
		discountPct: Number,
		hidePrevPrice: Boolean,
		hideDiscount: Boolean,
		priceClass: String,
		discountClass: String,
		prevPriceClass: String,
		currency: String,
	},
	computed: {
		hasPrevPrice() {
			return this.prevPrice && this.prevPrice > this.price
		},
		hasDiscountPct() {
			return this.discountPct > 0
		},
	},
}
</script>

<template>
	<div class="d-flex flex-column">
		<div v-if="!hidePrevPrice && hasPrevPrice">
			<ProductPrevPrice :amount="prevPrice" :customClass="prevPriceClass" :currency="currency" />
			<ProductDiscount
				v-if="!hideDiscount && hasDiscountPct"
				:amount="discountPct"
				class="ml-2"
				:customClass="discountClass"
			/>
		</div>
		<div v-else style="height: 24px"></div>
		<ProductPrice :amount="price" :custom-class="priceClass" class="mt-1" :currency="currency" />
	</div>
</template>
