<script>
export default {
	lang: 'home',
	computed: {
		negativeMargin() {
			return this.$vuetify.breakpoint.smAndDown ? 80 : 127
		},
		homeComponents() {
			return this.$srv('Home_Components.comps', []).map((x) => {
				if (x.includes('ProductsCarousel')) {
					let dataIdx = x.split('-').pop()
					return {
						comp: 'Home-ProductsCarousel',
						data: {
							...this.$srv(`Home_ProductsCarousel_${dataIdx}`, {}),
							listIdx: dataIdx,
						},
					}
				} else {
					return {
						comp: x,
						data: {},
					}
				}
			})
		},
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div class="home-container">
		<Home-ImagesSlider />
		<component v-for="(x, idx) of homeComponents" :key="idx" :is="x.comp" v-bind="x.data" />
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.home-container {
	margin-top: -$navh;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.home-container {
		margin-top: -$navhxs;
	}
}
</style>
