<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'layout',
	props: {
		showMenu: Boolean,
	},
	data() {
		return {
			searchText: null,
			businessInfo: this.$srv('BusinessInfo', {}),
			logoImg: this.$srv('LayoutHeaderLogo.img'),
			menuLinks: [],
			langItems: this.$srv('LangSelectorItems'),
			langIso: this.$srv('lang.iso'),
			searchOpen: false,
		}
	},
	computed: {
		user: get('shop/user'),
		routeSearch: get('route@query.search'),
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		order: get('cart/order'),
		orderItemsQty() {
			return this.order?.items.reduce((sum, item) => sum + item.qty, 0)
		},
		navbarHeight() {
			return this.isMobile ? 80 : 127
		},
		currentLangItem() {
			return this.langIso && this.langItems.find((item) => item.langIso == this.langIso)
		},
	},
	watch: {
		routeSearch(value) {
			if (value) this.searchText = value
		},
		searchOpen(value) {
			if (value) {
				this.$nextTick(() => this.$refs.searchInput.focus())
			}
		},
	},
	methods: {
		showLoginDrawer() {
			this.$store.set('shop/loginDrawer', true)
		},
		showCartDrawer() {
			this.$store.set('shop/cartDrawer', true)
		},
		showMenuMobileDrawer() {
			this.$store.set('shop/menuMobileDrawer', true)
		},
		search() {
			// this.searchOpen = false
			this.$shop.search(this.searchText)
		},
	},
	created() {
		let children = this.$srv('LayoutHeaderLinks_Children')
		let parents = this.$srv('LayoutHeaderLinks')

		children.forEach((child) => {
			parents.forEach((p) => {
				if (child.parent == p.position) {
					return (p.children = p.children ? [...p.children, child] : [child])
				}
			})
		})

		this.menuLinks = parents
	},
}
</script>

<template>
	<header :style="{ height: `${navbarHeight}px`, 'z-index': 10 }">
		<div class="d-flex align-center align-md-start px-2 px-md-8" style="max-width: 1400px; margin: 0 auto">
			<Button @click.stop="showMenuMobileDrawer" icon color="orange" class="d-md-none" v-if="!searchOpen">
				<v-icon>mdi-menu</v-icon>
			</Button>
			<v-spacer v-if="isMobile" />
			<router-link :to="{ name: 'home' }" v-if="logoImg" class="logo" :class="{ mobile: isMobile }">
				<img :alt="businessInfo.name" :src="logoImg" />
			</router-link>
			<!-- <v-spacer /> -->
			<div class="d-flex align-center pt-md-4 w100">
				<SafeLink
					class="mr-4 menu-item d-none d-md-block"
					v-for="(item, i) of menuLinks"
					:key="i"
					:to="item.link"
					custom
					v-slot="{ attrs }"
				>
					<v-menu open-on-hover bottom offset-y :disabled="!item.children || !item.children.length">
						<template v-slot:activator="{ on }">
							<Button v-bind="attrs" v-on="on" color="blue" text class="font-weight-bold">
								{{ item.text }}
							</Button>
						</template>

						<v-list :elevation="0" flat v-if="item.children && item.children.length" color="#FEF5EB">
							<v-list-item v-for="(child, index) of item.children" :key="index">
								<SafeLink :to="child.link">
									<v-list-item-title class="text-capitalize blue--text font-weight-bold">{{
										child.text
									}}</v-list-item-title>
								</SafeLink>
							</v-list-item>
						</v-list>
					</v-menu>
				</SafeLink>
				<v-spacer v-if="searchOpen" />
				<v-spacer v-else style="width: 100px" />
				<Button
					v-if="!searchOpen"
					class="font-weight-bold mr-4"
					icon
					color="blue"
					@click="searchOpen = true"
				>
					<v-icon>mdi-magnify</v-icon>
				</Button>
				<v-form
					v-if="searchOpen"
					class="d-block flex-grow-1 mr-md-4"
					:class="{ w100: isMobile }"
					:style="{ minWidth: isMobile ? 'unset' : '280px' }"
					@submit.prevent="search"
				>
					<v-text-field
						ref="searchInput"
						:class="`${isMobile && 'full-width rounded-0'} search-input`"
						type="search"
						autocomplete="off"
						placeholder="Buscar productos..."
						append-icon="mdi-magnify"
						color="blue"
						outlined
						tile
						solo
						flat
						hide-details
						clearable
						dense
						v-model="searchText"
						@click:append="search"
						@blur="searchOpen = false"
					/>
				</v-form>
				<Button
					class="font-weight-bold mr-4 d-none d-md-flex"
					text
					color="blue"
					:to="{ name: 'user.account' }"
				>
					<template v-if="user">
						<v-icon class="mr-2">mdi-account-circle</v-icon>
						{{ user.firstname }}
					</template>
					<template v-else>
						{{ 'Mi cuenta' | lang }}
					</template>
				</Button>
				<!-- 
				<v-menu open-on-hover bottom offset-y v-if="langItems.length">
					<template v-slot:activator="{ on }">
						<Button v-on="on" large icon color="blue" class="font-weight-bold mr-8">
							<template v-if="currentLangItem">
								<Media eager :src="currentLangItem.img" width="25" height="25" contain />
							</template>
							<template v-else> <v-icon class="ml-1">mdi-earth</v-icon> </template>
						</Button>
					</template>

					<v-list :elevation="1" color="#FEF5EB">
						<v-list-item
							v-for="(item, i) of langItems"
							:key="i"
							@click="$shop.changeLang(item.langIso)"
							class="px-5"
						>
							<div class="d-flex justify-start align-center">
								<Media class="mr-3" :src="item.img" eager width="25" height="25" contain />
								{{ item.langName }}
							</div>
						</v-list-item>
					</v-list>
				</v-menu> -->
				<Button @click.stop="showCartDrawer" icon color="darkblue" v-if="!isMobile || !searchOpen">
					<v-badge :content="orderItemsQty" :value="!!orderItemsQty" color="orange" overlap>
						<v-icon>mdi-cart-outline</v-icon>
					</v-badge>
				</Button>
				<!-- <SafeLink :to="businessInfo.facebook" class="mr-6 d-none d-md-block" v-if="businessInfo.facebook">
					<Button fab x-small color="orange">
						<v-icon>mdi-facebook</v-icon>
					</Button>
				</SafeLink>
				<SafeLink
					:to="businessInfo.instagram"
					class="mr-6 d-none d-md-block"
					v-if="businessInfo.instagram"
				>
					<Button fab x-small color="orange">
						<v-icon>mdi-instagram</v-icon>
					</Button>
				</SafeLink>
				<SafeLink :to="businessInfo.pinterest" class="d-none d-md-block" v-if="businessInfo.pinterest">
					<Button fab x-small color="orange">
						<v-icon>mdi-pinterest</v-icon>
					</Button>
				</SafeLink> -->
			</div>
		</div>
	</header>
</template>

<style scoped>
.logo {
	position: relative;
	top: 6px;
}
.logo img {
	width: auto;
	height: 118px;
}
.logo.mobile img {
	height: 68px;
}
.search-input {
	width: 100%;
}
.search-input ::v-deep .v-icon {
	color: var(--v-blue-base) !important;
}
.search-input ::v-deep .v-input__slot,
.search-input ::v-deep fieldset {
	background-color: transparent !important;
}
.search-input ::v-deep fieldset {
	border: 1px solid var(--v-blue-base) !important;
}
.search-input ::v-deep input {
	color: var(--v-blue-base) !important;
}
.full-width {
	width: 100%;
}

.menu-item {
	position: relative;
	min-width: auto;
}

.active-menu-item::before,
.menu-item::before {
	background-color: #000;
	position: absolute;
	width: 0;
	height: 2px !important;
	top: calc(50% + 10px);
	left: 0;
	bottom: 0;
	opacity: 0;
	transition: width 0.5s;
}

.active-menu-item::before,
.menu-item:hover::before {
	width: 100%;
	height: 3px;
	opacity: 1 !important;
}
.menu-item .v-btn {
	height: 46px !important;
	padding: 0 8px !important;
	min-width: 0 !important;
}
.menu-item ::v-deep .v-btn__content {
	line-height: 1.1 !important;
}
</style>
