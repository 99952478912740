var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Carousel',{staticClass:"product-card-carousel",attrs:{"items":_vm.products,"options":{
		itemsPadding: 2,
		panelsPerView: { xs: 1.2, sm: 3.5, md: 4 },
		arrows: { md: true },
		arrowsMargin: 30,
		itemWrapperClass: 'py-1',
	},"flicking-options":{
		align: 'prev',
		bound: true,
	}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
	var item = ref.item;
	var i = ref.i;
return [_c('ProductCard',{attrs:{"product":item,"list-id":_vm.listId,"list-index":i}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }