<script>
export default {
	name: 'ProductDiscount',
	props: {
		customClass: {
			type: String,
			default: 'white--text orange font-weight-bold font-0 rounded px-2',
		},
		amount: Number,
	},
}
</script>

<template>
	<DiscountText :amount="amount" :class="customClass" />
</template>
