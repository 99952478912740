<script>
export default {}
</script>

<template>
	<v-app>
		<!-- <v-overlay absolute :value="true"> </v-overlay> -->
		<AppBar />
		<MobileMenuDrawer />
		<LoginDrawer />
		<CartDrawer />
		<v-main>
			<router-view />
		</v-main>
		<Footer />
	</v-app>
</template>
