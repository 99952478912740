<script>
import { get, sync } from 'vuex-pathify'

export default {
	lang: 'layout',
	data() {
		return {
			businessInfo: this.$srv('BusinessInfo', {}),
			menuLinks: [],
		}
	},
	computed: {
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		user: get('shop/user'),
	},
	methods: {
		showLoginDrawer() {
			this.menuMobileDrawer = false
			this.$nextTick(() => {
				this.$store.set('shop/loginDrawer', true)
			})
		},
	},
	created() {
		let children = this.$srv('LayoutHeaderLinks_Children')
		let parents = this.$srv('LayoutHeaderLinks')

		children.forEach((child) => {
			parents.forEach((p) => {
				if (child.parent == p.position) {
					return (p.children = p.children ? [...p.children, child] : [child])
				}
			})
		})

		this.menuLinks = parents
	},
}
</script>

<template>
	<v-navigation-drawer
		class="pa-4 pr-0 pl-0 drawer-index blue"
		v-model="menuMobileDrawer"
		width="600"
		max-width="100%"
		overlay-color="#000"
		temporary
		:overlay-opacity="0.8"
		fixed
		touchless
	>
		<v-list-item class="pl-8 pr-6 pb-8 align-start">
			<router-link :to="{ name: 'home' }">
				<img
					:alt="$srv('BusinessInfo.name')"
					:src="$srv('LayoutHeaderLogo.img')"
					style="width: auto; height: 75px"
				/>
			</router-link>
			<v-spacer></v-spacer>
			<Button icon x-large @click="menuMobileDrawer = false" color="lightyellow">
				<v-icon large>mdi-close</v-icon>
			</Button>
		</v-list-item>
		<v-list-item
			v-if="user"
			:to="{ name: 'user.account' }"
			@click="menuMobileDrawer = false"
			class="font-3 font-weight-bold py-3 px-8 align-center menu-item"
		>
			<v-icon class="mr-2">mdi-account-circle</v-icon>
			<span>{{ user.firstname }}</span>
			<v-spacer />
			<v-icon large>mdi-chevron-right</v-icon>
		</v-list-item>
		<v-list-item
			v-else
			@click.stop="showLoginDrawer"
			class="align-center font-3 font-weight-bold py-3 px-8 menu-item"
		>
			<span>{{ 'Mi cuenta' | lang }}</span>
			<v-spacer />
			<v-icon>mdi-chevron-right</v-icon>
		</v-list-item>

		<!-- LINKS -->
		<div v-for="(item, index) of menuLinks" :key="index">
			<v-list-group
				class="align-center font-weight-bold py-3 px-4 menu-item text-capitalize"
				v-if="item.children"
				append-icon="mdi-chevron-down white--text"
			>
				<template v-slot:activator>
					<v-list-item-title class="font-3">
						{{ item.text }}
					</v-list-item-title>
				</template>
				<SafeLink v-for="(child, i) of item.children" :key="i" :to="child.link">
					<v-list-item class="align-center font-weight-bold py-3">
						<v-list-item-title class="font-3">{{ child.text }}</v-list-item-title>
					</v-list-item>
				</SafeLink>
			</v-list-group>
			<SafeLink :to="item.link" @click="menuMobileDrawer = false" v-else>
				<v-list-item class="font-3 font-weight-bold py-3 px-8 align-center menu-item">
					<div>{{ item.text }}</div>
					<v-spacer />
					<v-icon>mdi-chevron-right</v-icon>
				</v-list-item>
			</SafeLink>
		</div>

		<div class="mt-14 d-flex justify-center">
			<SafeLink :to="businessInfo.facebook" v-if="businessInfo.facebook">
				<Button fab color="orange" class="mx-3">
					<v-icon>mdi-facebook</v-icon>
				</Button>
			</SafeLink>
			<SafeLink :to="businessInfo.instagram" v-if="businessInfo.instagram">
				<Button fab color="orange" class="mx-3">
					<v-icon>mdi-instagram</v-icon>
				</Button>
			</SafeLink>
			<SafeLink :to="businessInfo.pinterest" v-if="businessInfo.pinterest">
				<Button fab color="orange" class="mx-3">
					<v-icon>mdi-pinterest</v-icon>
				</Button>
			</SafeLink>
			<SafeLink :to="`mailto:${businessInfo.email}`" v-if="businessInfo.email">
				<Button fab color="orange" class="mx-3">
					<v-icon>mdi-email</v-icon>
				</Button>
			</SafeLink>
		</div>
	</v-navigation-drawer>
</template>

<style scoped>
.menu-item {
	background-color: rgba(255, 255, 255, 0.1);
	margin-bottom: 6px;
}
.menu-item * {
	color: var(--v-lightyellow-base) !important;
}
</style>
