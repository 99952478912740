import {
	Lion,
	Ant,
	Chicken,
	Crocodile,
	Octopus,
	LionEnglish,
	AntEnglish,
	ChickenEnglish,
	CrocodileEnglish,
	OctopusEnglish,
	LionPortuguese,
	AntPortuguese,
	ChickenPortuguese,
	CrocodilePortuguese,
	OctopusPortuguese,
} from '@/site/autoloader'

export default [
	{
		path: '/animales/leon',
		component: Lion,
	},
	{
		path: '/animales/hormiga',
		component: Ant,
	},
	{
		path: '/animales/gallina',
		component: Chicken,
	},
	{
		path: '/animales/cocodrilo',
		component: Crocodile,
	},
	{
		path: '/animales/pulpo',
		component: Octopus,
	},

	// ENGLISH
	{
		path: '/animales/lion',
		component: LionEnglish,
	},
	{
		path: '/animales/ant',
		component: AntEnglish,
	},
	{
		path: '/animales/chicken',
		component: ChickenEnglish,
	},
	{
		path: '/animales/crocodile',
		component: CrocodileEnglish,
	},
	{
		path: '/animales/octopus',
		component: OctopusEnglish,
	},

	// PORTUGUESE
	{
		path: '/animales/leao',
		component: LionPortuguese,
	},
	{
		path: '/animales/formiga',
		component: AntPortuguese,
	},
	{
		path: '/animales/galinha',
		component: ChickenPortuguese,
	},
	{
		path: '/animales/crocodilo',
		component: CrocodilePortuguese,
	},
	{
		path: '/animales/polvo',
		component: OctopusPortuguese,
	},
]
