<script>
import smileIcon from './smile-icon.svg'
import sendIcon from './send-icon.svg'

export default {
	lang: 'home',
	name: 'NewsLetterForm',
	data() {
		return {
			email: '',
			loading: false,
			showSuccess: false,
			showError: false,
			smileIcon,
			sendIcon,
		}
	},
	watch: {
		showError(value) {
			if (value) {
				setTimeout(() => (this.showError = false), 1500)
			}
		},
	},
	methods: {
		saveEmail() {
			if (this.loading) return
			this.$shopApi.post({
				url: '/newsletter-email',
				data: {
					email: this.email,
				},
				loading: (v) => (this.loading = v),
				done: ({ success }) => {
					this.showSuccess = success
					this.showError = !success
				},
			})
		},
	},
}
</script>

<template>
	<div>
		<form @submit.prevent v-if="!showSuccess && !showError">
			<TextField
				type="email"
				v-model="email"
				:label="$lang('Tu email aquí')"
				@keydown.enter="saveEmail"
				outlined
				rounded
				solo
				flat
			>
				<template #append>
					<Media :src="smileIcon" @click="saveEmail" width="28" style="position: relative; left: 6px" />
				</template>
				<template #append-outer>
					<div class="pt-1">
						<v-progress-circular v-if="loading" indeterminate color="primary" size="24" />
						<Media :src="sendIcon" @click="saveEmail" v-else width="24" />
					</div>
				</template>
			</TextField>
		</form>
		<div v-if="showSuccess" class="green white--text w100 py-4 px-8 text-center rounded-xl">
			{{ 'Gracias por registrar tu correo!' | lang }}
		</div>
		<div v-if="showError" class="error white--text w100 py-4 px-8 text-center rounded-xl">
			{{ 'Ingresa un email válido' | lang }}
		</div>
	</div>
</template>
