import Srv from '@/__shared/utils/lib_srv'
import store from '@/site/store'
import { gtag, fbq } from '@/site/analytics/trackers'
import { eventer } from '@/__shared/utils/lib_eventer'
import { shopApi } from './plugin_shop-api'
import { isBotAgent } from '@/site/site/prerender'

// UPDATE USER ID ------------------------------------------
let _currUserId = null
function updateUserId(userId) {
	if (_currUserId == userId) return
	_currUserId = userId
	gtag('set', { user_id: userId })
	fbq('init', Srv('Analytics.fbpId'), { external_id: userId })
}

let user = store.get('shop/user')
if (user) updateUserId(user.id)
store.watch(
	() => store.get('shop/user'),
	(user) => user && updateUserId(user.id)
)

function getProductsEventData(items) {
	let { priceConfigId } = items[0].product.variants[0].pvPrice
	let productsData = items.map(({ product, variant, index = null }) => {
		if (!variant) {
			variant = product.variants.find((variant) => variant.main) || product.variants[0]
		}
		return {
			id: product.id,
			variantId: variant.id,
			index,
		}
	})
	return { priceConfigId, productsData }
}

eventer.listen('product-list:view', ({ products, listId = null, listName = null }) => {
	if (isBotAgent) return
	if (!products?.length) return
	let items = products.map((product, index) => {
		if (typeof product.index == 'number' && product.index >= 0) {
			index = product.index
		}
		return { product, index }
	})
	let eventData = {
		...getProductsEventData(items),
		listId,
		listName,
	}
	shopApi.post(`/shop-event/view-product-list`, { data: { eventData } })
})

eventer.listen('product:click', ({ product, variant, listId = null, listName = null, index = 0 }) => {
	if (isBotAgent) return
	let items = [{ product, variant, index }]
	let eventData = {
		...getProductsEventData(items),
		listId,
		listName,
	}
	shopApi.post(`/shop-event/click-product`, { data: { eventData } })
})

eventer.listen('product:view', ({ product, variant }) => {
	if (isBotAgent) return
	let items = [{ product, variant }]
	let eventData = getProductsEventData(items)
	shopApi.post(`/shop-event/view-product`, { data: { eventData } })
})
