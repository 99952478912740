<script>
export default {
	lang: 'books',
	props: {
		joinedItems: Array,
	},
}
</script>

<template>
	<div style="display: none">
		<template v-for="(jItem, i) of joinedItems">
			<portal
				:key="i"
				:to="`CartItem.${i}.below-descriptions`"
				v-if="jItem.items[0].customization && jItem.items[0].customization.key !== 'withoutCustomization'"
			>
				<div class="py-2">
					<Button
						color="green"
						small
						rounded
						dark
						:to="{
							name: 'product',
							params: {
								urlName: jItem.items[0].cartData.urlName,
								id: jItem.items[0].cartData.productId,
							},
							query: { customizationId: jItem.items[0].customization.id },
						}"
					>
						<v-icon small class="mr-1">mdi-lead-pencil</v-icon>{{ 'Editar' | lang }}
					</Button>
				</div>
			</portal>
		</template>
	</div>
</template>
