<script>
import fiscalImage from './asset_fiscal.png'
import { get } from 'vuex-pathify'

export default {
	lang: 'layout',
	data() {
		return {
			desktopGroups: [
				{
					header: this.$lang('Para ti'),
					items: [
						{ text: this.$lang('Mi cuenta'), to: { name: 'user.account' } },
						{ text: this.$lang('Blog'), to: 'http://blog.plickme.com/' },
					],
				},
				{
					header: this.$lang('Sobre Nosotros'),
					items: [
						{ text: this.$lang('¿Quiénes somos?'), to: { name: 'others.about-us' } },
						{
							text: this.$lang('Mi Astralito'),
							to: '/p/mi-astralito/595010e1-a90f-4f39-8041-f26becb556d4',
						},
					],
				},
				{
					header: this.$lang('Atención al Cliente'),
					items: [
						{ text: this.$lang('Preguntas frecuentes'), to: { name: 'others.faq' } },
						{ text: this.$lang('Nuestra Garantía'), to: { name: 'others.our-guarantee' } },
						{ text: this.$lang('Términos y condiciones'), to: { name: 'others.terms' } },
						{ text: this.$lang('Políticas de privacidad'), to: { name: 'others.privacy' } },
					],
				},
				{
					header: this.$lang('Páginas que amamos'),
					items: [
						{ text: 'Seleccciones', to: 'https://tiendaselecciones.com', attrs: { rel: 'sponsored' } },
						{ text: 'Bien Natural', to: 'https://biennatural.com.ar', attrs: { rel: 'sponsored' } },
						{ text: 'Sabor de Casa', to: 'https://sabordecasa.com.ar', attrs: { rel: 'sponsored' } },
					],
				},
			],
			fiscalImage,
			logo: this.$srv('LayoutHeaderLogo.img'),
			businessInfo: this.$srv('BusinessInfo', {}),
		}
	},
	computed: {
		showFooter: get('layout/showFooter'),
	},
}
</script>

<template>
	<v-footer v-if="showFooter" padless color="blue" class="text-center pt-4 pt-sm-14 pb-7">
		<Container>
			<v-row>
				<v-col class="d-sm-none" cols="12" />
				<v-col cols="12" sm="3" v-for="(group, i) in desktopGroups" :key="i" class="text-sm-left">
					<div class="pb-3 font-weight-bold white--text">
						{{ group.header }}
					</div>
					<div v-for="(item, i) of group.items" :key="i" class="pb-1">
						<SafeLink :to="item.to" class="white--text" v-bind="item.attrs || {}">
							{{ item.text }}
						</SafeLink>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="6" class="d-flex justify-center justify-sm-end align-center text-caption">
					{{ new Date().getFullYear() }} | Plickme. {{ 'Todos los derechos reservados.' | lang }}
				</v-col>
				<v-col class="order-first order-sm-last" cols="12" sm="6">
					<SafeLink :to="businessInfo.facebook" v-if="businessInfo.facebook">
						<Button fab color="orange" class="mx-3">
							<v-icon>mdi-facebook</v-icon>
						</Button>
					</SafeLink>
					<SafeLink :to="businessInfo.instagram" v-if="businessInfo.instagram">
						<Button fab color="orange" class="mx-3">
							<v-icon>mdi-instagram</v-icon>
						</Button>
					</SafeLink>
					<SafeLink :to="businessInfo.pinterest" v-if="businessInfo.pinterest">
						<Button fab color="orange" class="mx-3">
							<v-icon>mdi-pinterest</v-icon>
						</Button>
					</SafeLink>
					<SafeLink :to="`mailto:${businessInfo.email}`" v-if="businessInfo.email">
						<Button fab color="orange" class="mx-3">
							<v-icon>mdi-email</v-icon>
						</Button>
					</SafeLink>
				</v-col>
			</v-row>
		</Container>
	</v-footer>
</template>

<style scoped>
.v-footer >>> * {
	color: white;
}
.link-item {
	padding: 6px 0;
	color: white;
}
a.link-item {
	display: block;
	text-decoration: none;
}
.link-item >>> .v-icon {
	margin-right: 4px;
	color: white;
}
</style>
