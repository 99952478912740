<script>
export default {
	data() {
		return {
			items: this.$srv('Home_A_BooksSliders', []),
		}
	},
}
</script>

<template>
	<div class="py-2 lightyellow" v-if="items.length">
		<Container :fluid="{ xs: true, md: false }">
			<Carousel
				:items="items"
				:options="{
					panelsPerView: { xs: 2.5, sm: 4.5, md: 6 },
					itemsPadding: 3,
					arrows: { md: true },
					arrowsMargin: 15,
					arrowsClass: 'blue--text',
					itemWrapperClass: 'py-1',
				}"
				:flicking-options="{
					align: 'prev',
					bound: true,
					circular: { xs: true, md: false },
					moveType: { xs: 'freeScroll', md: 'snap' },
				}"
			>
				<template #panel="{ item }">
					<SafeLink :to="item.link">
						<v-hover v-slot="{ hover }">
							<Media
								class="img-item"
								:class="{ 'img-item--hover': hover }"
								:src="item.img"
								:aspect-ratio="1"
								contain
								eager
							/>
						</v-hover>
					</SafeLink>
				</template>
			</Carousel>
		</Container>
	</div>
</template>

<style scoped>
.img-item {
	transition: all 0.3s;
}
.img-item--hover {
	transform-origin: center;
	transform: scale(1.1);
}
</style>
