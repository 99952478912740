import {
	TermsAndConditions,
	Faq,
	Privacy,
	AboutUs,
	OurGuarantee,
	FreeIssue,
	FreeIssue_LetterSantaClous,
	FreeIssueThanks,
	FreeIssue_Amor,
	FreeIssue_ABC,
	FreeIssue_AstralitoAdultos,
	FreeIssue_Tasks,
	FreeIssue_Amistad,
} from '@/site/autoloader'

export default [
	{
		path: '/terminos-y-condiciones',
		name: 'others.terms',
		component: TermsAndConditions,
	},
	{
		path: '/privacidad',
		name: 'others.privacy',
		component: Privacy,
	},
	{
		path: '/faq',
		name: 'others.faq',
		component: Faq,
	},
	{
		path: '/quienes-somos',
		name: 'others.about-us',
		component: AboutUs,
	},
	{
		path: '/nuestra-garantia',
		name: 'others.our-guarantee',
		component: OurGuarantee,
	},
	/* FREE ISSUES */
	{
		path: '/libro-gratis',
		name: 'others.free-issue-free-book',
		component: FreeIssue,
	},
	{
		path: '/carta-papa-noel',
		name: 'others.free-issue-letter-santa-clous',
		component: FreeIssue_LetterSantaClous,
	},
	{
		path: '/amor',
		name: 'others.free-issue-amor',
		component: FreeIssue_Amor,
	},
	{
		path: '/free-issue-abc',
		name: 'others.free-issue-abc',
		component: FreeIssue_ABC,
	},
	{
		path: '/free-issue-zodiacario',
		name: 'others.free-issue-zodiacario',
		component: FreeIssue_AstralitoAdultos,
	},
	/* FREE ISSUES THANK YOU PAGE */
	{
		path: '/libro-gratis-gracias/:key',
		name: 'others.free-issue-thanks',
		component: FreeIssueThanks,
	},
	{
		path: '/tareas',
		name: 'others.tasks',
		component: FreeIssue_Tasks,
	},
	{
		path: '/amistad',
		name: 'others.amistad',
		component: FreeIssue_Amistad,
	},
]
