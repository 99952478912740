<script>
import { VImg } from 'vuetify/lib'
import { isBotAgent } from '@/site/site/prerender'
export default {
	extends: VImg,
	props: {
		eager: {
			type: Boolean,
			default: isBotAgent,
		},
	},
}
</script>
