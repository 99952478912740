<script>
export default {
	props: {
		rounded: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		fontColorClass() {
			if (this.$attrs.text) return ''
			switch (this.$attrs.color) {
				case 'orange':
				case 'green':
				case 'lightgreen':
				case 'blue':
				case 'darkblue':
				case 'lightyellow':
				case 'pink':
				case 'darkpink':
				case 'link':
					return 'white--text'
				default:
					return ''
			}
		},
	},
}
</script>
<template>
	<v-btn v-bind="{ ...$attrs, ...$props }" v-on="$listeners" :class="fontColorClass">
		<slot />
	</v-btn>
</template>
