<script>
import vector from './vector-bg.svg'

export default {
	name: 'ImageCardSection',
	data() {
		return {
			itemsGroups: this.$srv('Home_B_Cards', []),
			vector,
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		imagesRatio() {
			return this.isMobile ? 1 : 1.88
		},
	},
}
</script>

<template>
	<div
		class="pb-12 pt-16 vector-bg"
		v-if="itemsGroups.length"
		:style="`background-image: url('${vector}');`"
	>
		<Container>
			<v-row class="pb-4" v-for="(data, i) of itemsGroups" :key="i">
				<v-col cols="12" sm="6">
					<Home-ImageCard
						:image="isMobile ? data.imgMobile1 : data.img1"
						:ratio="imagesRatio"
						:cta-txt="data.ctaText1"
						:cta-link="data.ctaLink1"
					/>
				</v-col>
				<v-col cols="12" sm="6">
					<Home-ImageCard
						:image="isMobile ? data.imgMobile2 : data.img2"
						:ratio="imagesRatio"
						:cta-txt="data.ctaText2"
						:cta-link="data.ctaLink2"
					/>
				</v-col>
				<v-col cols="12" v-if="data.ctaLinkSection && data.ctaTextSection" class="d-flex justify-center">
					<SafeLink :to="data.ctaLinkSection">
						<Button color="darkblue" text large>
							{{ data.ctaTextSection }}
							<v-icon class="ml-1">mdi-arrow-right</v-icon>
						</Button>
					</SafeLink>
				</v-col>
			</v-row>
		</Container>
	</div>
</template>

<style scoped>
.vector-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
}
</style>
