var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"none"}},[_vm._l((_vm.joinedItems),function(jItem,i){return [(jItem.items[0].customization && jItem.items[0].customization.key !== 'withoutCustomization')?_c('portal',{key:i,attrs:{"to":("CartItem." + i + ".below-descriptions")}},[_c('div',{staticClass:"py-2"},[_c('Button',{attrs:{"color":"green","small":"","rounded":"","dark":"","to":{
						name: 'product',
						params: {
							urlName: jItem.items[0].cartData.urlName,
							id: jItem.items[0].cartData.productId,
						},
						query: { customizationId: jItem.items[0].customization.id },
					}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-lead-pencil")]),_vm._v(_vm._s(_vm._f("lang")('Editar'))+" ")],1)],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }