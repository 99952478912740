// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
	showFooter: true,
}

const mutations = make.mutations(state)

const actions = make.actions(state)

const getters = make.getters(state)

export default {
	name: 'layout',
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
