<script>
export default {
	props: {
		title: String,
		products: Array,
		ctaTxt: String,
		ctaLink: String,
	},
	computed: {
		showCta() {
			return this.ctaTxt && this.ctaLink
		},
	},
}
</script>

<template>
	<Container class="py-16" :fluid="$vuetify.breakpoint.smAndDown">
		<div class="text-center title pb-8 font-8 font-weight-bold darkblue--text">
			{{ title }}
		</div>
		<div v-if="showCta" class="pb-8 d-flex justify-center">
			<SafeLink :to="ctaLink">
				<Button class="primary--text" x-large tile outlined>
					{{ ctaTxt }}
				</Button>
			</SafeLink>
		</div>
		<ProductsCarousel :products="products" />
	</Container>
</template>
