<script>
export default {
	name: 'ProductCardImage',
	props: {
		mainImage: String,
		secondImage: String,
	},
	data() {
		return {
			firstHover: false,
		}
	},
}
</script>

<template>
	<v-hover v-slot="{ hover }">
		<div class="parent-container" @mouseover="firstHover = true">
			<Media width="100%" height="auto" contain aspect-ratio="1" :src="mainImage">
				<template #placeholder>
					<div class="fill-height d-flex align-center justify-center">
						<v-progress-circular indeterminate color="primary" />
					</div>
				</template>
			</Media>
			<div class="child-container" v-if="secondImage && firstHover">
				<Media
					width="100%"
					height="auto"
					contain
					class="second-image"
					:class="{ 'second-image--show': hover }"
					aspect-ratio="1"
					:src="secondImage"
				>
					<template #placeholder>
						<div class="fill-height d-flex align-center justify-center">
							<v-progress-circular indeterminate color="primary" />
						</div>
					</template>
				</Media>
			</div>
		</div>
	</v-hover>
</template>

<style scoped>
.parent-container {
	position: relative;
}
.child-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.second-image {
	transition: opacity 0.3s;
	opacity: 0;
}
.second-image--show {
	opacity: 1;
}
</style>
