import Vue from 'vue'
import Vuetify from 'vuetify/lib'
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const vuetify = new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#5272b7',
				secondary: '#FFF',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				//success: '#4CAF50',
				success: '#59bba5',
				warning: '#FFC107',
				link: '#3A4D9B',
				orange: '#ec634a',
				green: '#59bba5',
				lightgreen: '#cbe6df',
				blue: '#5272b7',
				darkblue: '#3A4D9B',
				lightyellow: '#FEF5EB',
				lightorange: '#FFBE36',
				darkorange: '#DA5830',
				pink: '#ffdfe3',
				darkpink: '#c1418a',
				lightpurple: '#CBD2DB',
			},
		},
	},
})

export default { vuetify }
