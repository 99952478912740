<script>
import { sync } from 'vuex-pathify'

export default {
	data() {
		return { idx: null, loaded: false }
	},
	computed: {
		...sync('autoLazy/*'),
		renderLazyComp() {
			return this.idx <= this.lastLazyLoadedIndex + 1
		},
	},
	watch: {
		loaded(value) {
			if (!value) return
			this.$nextTick(() => {
				if (this.lastLazyLoadedIndex < this.idx) {
					this.lastLazyLoadedIndex = this.idx
				}
			})
		},
		lastLazyLoadedIndex(value) {
			if (value > this.idx && !this.loaded) {
				this.loaded = true
			}
		},
	},
	created() {
		this.idx = this.lazyIndex += 1
	},
}
</script>

<template>
	<div v-if="$isBotAgent"><slot /></div>
	<v-lazy v-model="loaded" v-else-if="renderLazyComp">
		<slot />
	</v-lazy>
</template>
