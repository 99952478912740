<script>
import rainbow_img from './rainbow.png'
import howWorksStepsImgDesktop from './how-works-desktop.svg'
import howWorksStepsImgMobile from './how-works-mobile.svg'
import howWorksStepsImgDesktop_pt from './how-works-desktop--pt.svg'
import howWorksStepsImgMobile_pt from './how-works-mobile--pt.svg'
import howWorksStepsImgDesktop_en from './how-works-desktop--en.jpg'
import howWorksStepsImgMobile_en from './how-works-mobile--en.jpg'

import { langIso } from '@/__shared/lang/lib_lang'

export default {
	lang: 'home',
	data() {
		return {
			rainbow_img,
		}
	},
	computed: {
		xs() {
			return this.$vuetify.breakpoint.xs
		},
		stepsImage() {
			if (langIso == 'pt') {
				return this.xs ? howWorksStepsImgMobile_pt : howWorksStepsImgDesktop_pt
			} else if (langIso == 'en') {
				return this.xs ? howWorksStepsImgMobile_en : howWorksStepsImgDesktop_en
			} else {
				return this.xs ? howWorksStepsImgMobile : howWorksStepsImgDesktop
			}
		},
		stepsRatio() {
			return this.xs ? 0.7133 : 5.147
		},
	},
}
</script>

<template>
	<Container class="py-8 py-md-16">
		<div class="d-flex flex-column justify-center align-center pb-8 pb-md-16">
			<Media :src="rainbow_img" width="197" :aspect-ratio="1.908" eager />
			<div class="font-8 text-center font-weight-bold pt-4 darkblue--text">
				{{ '¿Cómo crear mi libro?' | lang }}
			</div>
		</div>

		<v-row align="center">
			<v-col cols="12" md="3" class="text-center text-md-left py-8 py-md-0 font-2 font-md-1">
				<div class="px-3 px-md-0">
					<span>{{ 'Estás a 3 simples pasos de personalizar el libro de tu peque.' | lang }}</span>
					<span class="orange--text font-weight-bold">
						{{
							'¡Imagina su carita al ver su nombre dentro de la historia y saber que él es el héroe!'
								| lang
						}}
					</span>
				</div>
			</v-col>
			<v-col cols="12" md="9">
				<div class="px-8 px-sm-0 pl-md-16">
					<Media :src="stepsImage" width="100%" :aspect-ratio="stepsRatio" contain eager />
				</div>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped>
.arrows-parent {
	position: relative;
}
.arrows {
	opacity: 0.6;
}
.arrows-landscape-lg {
	position: absolute;
	width: 570px;
	left: -244px;
}
.arrows-landscape-sm {
	position: absolute;
	width: 365px;
	left: -139px;
}
.arrows-portrait {
	position: absolute;
	width: 286px;
	left: -103px;
	top: -69px;
}
</style>
