<script>
export default {
	lang: 'home',
	props: {
		products: Array,
		title: String,
		bgcolor: String,
		textcolor: String,
		listIdx: String,
		seeMoreUrl: String,
	},
}
</script>

<template>
	<div
		v-if="products && products.length"
		width="100%"
		class="py-12 px-md-10 px-lg-16"
		:style="`background: ${bgcolor || 'var(--v-lightyellow-base)'}`"
	>
		<Container class="py-2 d-flex align-center mb-3">
			<div
				class="font-weight-bold d-flex align-center w100 flex-column flex-md-row"
				:style="`color: ${textcolor || 'var(--v-darkblue-base)'}`"
			>
				<span class="font-3 font-sm-5">
					{{ title }}
				</span>
				<v-spacer />
				<v-btn
					v-if="seeMoreUrl"
					:to="seeMoreUrl"
					text
					class="font-1 font-weight-bold"
					:color="textcolor || 'darkblue'"
				>
					{{ 'Ver más' | lang }}
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</div>
		</Container>
		<Container :fluid="{ xs: true, md: false }">
			<ProductsCarousel :products="products" :list-id="`home-products-carousel-${listIdx}`" />
		</Container>
	</div>
</template>
<style scoped>
.carousel >>> .arrow-icon {
	color: var(--v-darkblue-base) !important;
}
</style>
