import { BookEditor } from '@/site/autoloader'

export default [
	/*{
		path: `/book-preview/:customizationId`,
		name: 'book-preview',
		component: BookEditor,
		meta: {
			showFooter: false,
		},
	},*/
]
