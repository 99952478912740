<script>
export default {
	data() {
		return {
			data: this.$srv('Home_I_Newsletter'),
		}
	},
	props: {
		img: String,
		imgMobile: String,
	},
}
</script>

<template>
	<Container class="mb-12" :fluid="{ xs: true, md: false }" v-if="data" style="position: relative">
		<Media
			:class="{ 'rounded-xl elevation-2': $vuetify.breakpoint.mdAndUp }"
			:aspect-ratio="$vuetify.breakpoint.xs ? 1 : 4.059"
			:src="
				img
					? $vuetify.breakpoint.smAndUp
						? img
						: imgMobile
					: $vuetify.breakpoint.smAndUp
					? data.img
					: data.imgMobile
			"
		>
		</Media>
		<div :class="`form-container${$vuetify.breakpoint.smAndUp ? '' : '--mobile'}`">
			<NewsLetterForm />
		</div>
	</Container>
</template>

<style scoped>
.form-container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 50px;
	width: 360px;
}
.form-container--mobile {
	position: absolute;
	bottom: 97px;
	padding: 0 12px;
	opacity: 0.9;
	width: 100%;
}
</style>
