<script>
import our_clients_img1 from './our_clients_img1.png'
import our_clients_img2 from './our_clients_img2.png'
import our_clients_img3 from './our_clients_img3.png'

export default {
	lang: 'home',
	data() {
		return {
			items: this.$srv('Home_H_Reviews', []),
			our_clients_img1,
			our_clients_img2,
			our_clients_img3,
		}
	},
	props: {
		images: [],
		adult: Boolean,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
		img1W() {
			return this.$vuetify.breakpoint.xs ? 140 : this.$vuetify.breakpoint.sm ? 150 : 200
		},
		img3W() {
			return this.$vuetify.breakpoint.xs ? 0 : this.$vuetify.breakpoint.sm ? 80 : 120
		},
	},
	created() {
		console.log('items', this.items)
	},
}
</script>

<template>
	<div v-if="items.length" class="py-12">
		<Container class="pb-2">
			<v-row>
				<v-col cols="12" class="d-flex align-center align-sm-start" v-if="!adult">
					<div>
						<Media :src="our_clients_img1" :width="img1W" :aspect-ratio="1.012" />
					</div>
					<div class="flex-grow-1 d-flex justify-center flex-column align-center px-sm-8">
						<div class="mb-4" v-if="$vuetify.breakpoint.smAndUp">
							<Media :src="our_clients_img2" :width="110" :aspect-ratio="2.23" />
						</div>
						<div class="font-5 font-sm-8 text-center font-weight-bold mb-5 darkblue--text">
							{{ 'Los más elegidos' | lang }}
						</div>
					</div>
					<div v-if="$vuetify.breakpoint.smAndUp" class="d-flex h100 align-end">
						<Media :src="our_clients_img3" :width="img3W" :aspect-ratio="1.21" />
					</div>
				</v-col>
				<v-col v-else cols="12">
					<div class="font-5 font-sm-8 text-center font-weight-bold mb-5 darkblue--text">
						{{ 'Los más elegidos' | lang }}
					</div>
				</v-col>
			</v-row>
		</Container>
		<Container :fluid="$vuetify.breakpoint.smAndDown">
			<Carousel
				:items="images ? images : items"
				:options="{
					panelsPerView: { xs: 1.7, sm: 3.5, md: 5 },
					itemsPadding: 2,
					arrows: { md: true },
					arrowsMargin: 15,
					arrowsClass: 'blue--text',
					itemWrapperClass: 'py-1',
				}"
				:flicking-options="{
					align: 'prev',
					bound: true,
					moveType: { xs: 'freeScroll', md: 'snap' },
				}"
			>
				<template #panel="{ item }">
					<Media :src="item.img" class="rounded-xl elevation-2" contain :aspect-ratio="0.48" />
				</template>
			</Carousel>
		</Container>
	</div>
</template>

<style scoped>
.panel {
	cursor: pointer;
}
</style>
