var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{staticClass:"py-12"},[_c('Container',{staticClass:"pb-2"},[_c('v-row',[(!_vm.adult)?_c('v-col',{staticClass:"d-flex align-center align-sm-start",attrs:{"cols":"12"}},[_c('div',[_c('Media',{attrs:{"src":_vm.our_clients_img1,"width":_vm.img1W,"aspect-ratio":1.012}})],1),_c('div',{staticClass:"flex-grow-1 d-flex justify-center flex-column align-center px-sm-8"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"mb-4"},[_c('Media',{attrs:{"src":_vm.our_clients_img2,"width":110,"aspect-ratio":2.23}})],1):_vm._e(),_c('div',{staticClass:"font-5 font-sm-8 text-center font-weight-bold mb-5 darkblue--text"},[_vm._v(" "+_vm._s(_vm._f("lang")('Los más elegidos'))+" ")])]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex h100 align-end"},[_c('Media',{attrs:{"src":_vm.our_clients_img3,"width":_vm.img3W,"aspect-ratio":1.21}})],1):_vm._e()]):_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-5 font-sm-8 text-center font-weight-bold mb-5 darkblue--text"},[_vm._v(" "+_vm._s(_vm._f("lang")('Los más elegidos'))+" ")])])],1)],1),_c('Container',{attrs:{"fluid":_vm.$vuetify.breakpoint.smAndDown}},[_c('Carousel',{attrs:{"items":_vm.images ? _vm.images : _vm.items,"options":{
				panelsPerView: { xs: 1.7, sm: 3.5, md: 5 },
				itemsPadding: 2,
				arrows: { md: true },
				arrowsMargin: 15,
				arrowsClass: 'blue--text',
				itemWrapperClass: 'py-1',
			},"flicking-options":{
				align: 'prev',
				bound: true,
				moveType: { xs: 'freeScroll', md: 'snap' },
			}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
			var item = ref.item;
return [_c('Media',{staticClass:"rounded-xl elevation-2",attrs:{"src":item.img,"contain":"","aspect-ratio":0.48}})]}}],null,false,3922356399)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }