import store from '@/site/store'
import { UserAccount, UserFavorites, UserOrders, UserOrderDetail, UserContentFiles, SharedContentFiles } from '@/site/autoloader'

const beforeEnter = (to, from, next) => {
	if (!store.get('shop/user')) {
		if (!from.name) next({ name: 'home' })
		store.set('shop/loginDrawer', true)
	} else {
		next()
	}
}

export default [
	{
		beforeEnter,
		path: '/user/account',
		name: 'user.account',
		component: UserAccount,
	},
	{
		beforeEnter,
		path: '/user/favorites',
		name: 'user.favorites',
		component: UserFavorites,
	},
	{
		beforeEnter,
		path: '/user/orders',
		name: 'user.orders',
		component: UserOrders,
	},
	{
		beforeEnter,
		path: '/user/orders/:id',
		name: 'user.order-detail',
		component: UserOrderDetail,
	},
	{
		beforeEnter,
		path: '/user/content-files',
		name: 'user.content-files',
		component: UserContentFiles,
	},
	{
		path: '/shared-content-files/:hash',
		name: 'shared.content-files',
		component: SharedContentFiles,
	}
]
